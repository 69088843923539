@font-face {
    font-family: 'Montserrat-Bold';
    src: url('../../Resources/Fonts/Montserrat-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

.spotlight {
    padding-top: 10%;
    position: relative;
    width: 100%;
    height: 100%; /* Use viewport height */
}

.content {
    display: flex;
    align-items: center;
    height:fit-content;
}

.text-container {
    width: 50%; /* Adjust width for landscape orientation */
    margin: 0 auto; /* Center the text container */
    margin-left: 10%; /* Adjust the left margin to shift it slightly towards the left */
    margin-right: 10%;
    height: 100%;
}

.Topic2 {
    font-family: 'Montserrat-Bold', sans-serif;
    font-weight: bold;
    font-size: 3vw; /* Use viewport width */
    color: #000000;
}

.Titled-Text2 {
    font-family: Montserrat, sans-serif;
    font-weight: bold;
    font-size: 3vw; /* Use viewport width */
    color: #000000;
}

.Subtext1 {
    font-family: Montserrat, sans-serif;
    font-weight: 700;
    font-size: 1.7vw; /* Use viewport width */
    color: #000000;
    margin-top: 2vh; /* Adjust margin as needed */
}

.bulb-container {
    position: relative;
    display: flex; /* Use flexbox to align images horizontally */
    justify-content: flex-start; /* Align images to the right */
    width: 40%; /* Adjust the width as needed */
    height: 80%;
    margin-right: 8%;
    margin-top: -30%; /* Adjust margin using viewport height */
    gap: 5%; /* Adjust gap as needed */
}

.bulb-container img {
    width: 17%; /* Adjust width as needed */
    height: 80%; /* Maintain aspect ratio */
    object-fit: contain;
    transition: transform 2s ease-in-out;
    margin-top: 0;
    margin-right: 2%;
    transform-origin: top center;
}

.bulb-container img:hover {
    width: 20%; /* Adjust width as needed */
    margin-top: 10vh; /* Adjust the margin-top for the downward movement */
}

.EnButton-white {
    padding: 3vw 7vw; /* Increase padding using viewport width */
    background-color: transparent;
    border: none;
    cursor: pointer;
    background-image: url('../assets/Asset 10.png');
    background-size: 100% 100%; /* Ensure the background image scales with the button */
    background-repeat: no-repeat;
    background-position: center;
    transition: background-color 0.3s ease, transform 0.3s ease; /* Include the transform property in the transition */
}

.EnButton-white:hover {
    transform: scale(1.1); /* Adjust the scale factor for enlargement */
    transition: background-color 0.3s ease, transform 0.3s ease; /* Include the transform property in the transition */
}

@media screen and  (orientation: portrait) {
    .text-container {
        width: 90%; /* Adjust width for smaller screens */
        margin: 0 auto; /* Center the text container */
        margin-top: 10%; /* Adjust margin-top for spacing */
        margin-left: 5%;
        height: 100%;
    }

    .Topic2 {
        font-size: 7vw; /* Use viewport width for smaller screens */
    }

    .Titled-Text2 {
        font-size: 7vw; /* Use viewport width for smaller screens */
    }

    .Subtext1 {
        font-size: 3.5vw;
    }

    .EnButton-white {
        padding: 8vw 20vw; /* Increase padding for mobile view */
        background-size: 100% 100%; /* Adjust background size for mobile view */
        margin-left: 0vw;
    }

    .Subtext {
        font-size: 4vw; /* Use viewport width for smaller screens */
    }

    .image-container {
        display: none; /* Hide the image container for smaller screens */
    }

    .bulb-container {
        display: none;
    }
}

