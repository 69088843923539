.list-courses-body {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #000;
}

.list-courses {
  color: #fff;
  padding: 2vh 10vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: max-height 0.4s ease; /* Smooth transition for height changes */
  overflow: hidden; /* Ensure no overflow */
  max-height: 280vh;
}

.title {
  font-family: 'Montserrat-Bold', sans-serif;
  font-weight: bold;
  font-size: 2.7vw; /* Adjust title font size */
  margin-bottom: 5vh; /* Add space below title */
  margin-top: 5vh;
}

.course-list a {
  display: flex;
  justify-content: space-between; /* Add space between text and arrow */
  align-items: center;
  color: #7f7f7f;
  text-decoration: none;
  font-size: 2vw; /* Adjust link font size */
  margin-bottom: 3.5vh; /* Add space between links */
  font-weight: 800;
}

.course-list a .arrow {
  width: 3vw; /* Adjust arrow size */
  transition: transform 0.3s ease;
}

.course-list a.selected .arrow {
  transform: rotate(180deg); /* Rotate arrow when selected */
}

.course-list a.selected {
  color: aqua; /* Change font color to blue when selected */
}

.course-list hr {
  border: none;
  border-top: 1px solid #7f7f7f;
  margin: 4vh 0; /* Add space between horizontal lines */
}

.SubCourses {
  margin-top: 3vh; /* Add some space between main course and sub-courses */
}

.SubCourses a {
  display: block; /* Display each sub-course on a new line */
  margin-bottom: 3vh; /* Add space between sub-courses */
  font-size: 2vw; /* Adjust sub-course link font size */
  color: #fff;
  font-weight: 450;
}

.SubCourses a:hover {
  text-decoration: underline;
}

@media screen and (orientation: portrait) {
  .list-courses {
    padding: 10% 5%;
  }

  .title {
    font-size: 8vw; /* Adjust title font size for mobile */
  }

  .course-list a {
    font-size: 6vw; /* Adjust link font size for mobile */
  }

  .course-list a .arrow {
    font-size: 5vw; /* Adjust arrow size for mobile */
  }

  .SubCourses a {
    font-size: 6vw; /* Adjust sub-course link font size for mobile */
  }
}
