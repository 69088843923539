/* HeroFace.css */
/*responsive : yes*/
/*responsive test-status: */

.hero-face {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 80vh;
  /* Adjust the height as needed */
}

.content1 {
  display: flex;
  align-items: center;
  height: 75vh;
}

.text-container1 {
  width: 40%;
  /* Adjust width as needed */
  margin: 0 auto;
  /* This will center the text container */
  margin-left: 10%;
  margin-top: 10%;
  /* Adjust the left margin to shift it slightly towards the left */
}

@font-face {
  font-family: 'Montserrat-Semibold';
  src: url('../../Resources/Fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
.Topic1 {
  font-family: 'Montserrat-Semibold', sans-serif;
  font-weight: 900;
  font-size: 5vh;
  color: #000000;
}

.Description {
  font-family: Montserrat;
  font-size: 3.7vh;
  font-weight: 550;
  color: #000000;
  margin-top: 20px;
  /* Adjust margin as needed */
}

.image-container-heroface {
  position: absolute;
  top: 0%;
  right: 0;
  width: 60%;
  /* Adjust the width as needed */
  height: 85%;
  overflow: hidden;
}

.image-container-heroface img {
  width: 200%;
  height: 100%;
  object-fit: contain;
  transform-origin: center;
  transition: transform 0.5s ease;
}

/* Add animation */
.hero-face:hover .image-container-heroface img {
  animation: rotateAnimation 5s linear infinite;
  /* Adjust duration and timing function as needed */
}

@keyframes rotateAnimation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.EnButton-white1 {
  padding: 35px 90px;
  /* Adjust the padding to increase the size */
  width: 10vw;
  height: 10vh;
  margin-left: 0%;
  margin-top: 2%;
  background-color: transparent;
  border: none;
  cursor: pointer;
  background-image: url('../assets/Asset\ 10.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transition: background-color 0.3s ease;

}

.EnButton-white1:hover {
  transform: scale(1.1);
  /* Adjust the scale factor for enlargement */
  transition: background-color 0.3s ease, transform 0.3s ease;
  /* Include the transform property in the transition */
}



@media (max-width: 600px) {
  .hero-face {
    position: relative;
    width: 100%;
    height: 50vh;
    min-height: 40vh;
}

  .Topic1 {
    font-family: Montserrat;
    font-weight: 900;
    font-size: 9vw;
    text-align: center;
    color: #000000;
  }

  .EnButton-white1 {
    background-size: 20vh;
    display:block; 
    margin: 0 auto;
  }

  .text-container1 {
    width: 90%;
    margin-left: 5%;
  }

  .content1 {
    display: flex;
    align-items: center;
    height: 75vh;
    flex-direction: column;
    justify-content: flex-start;
  }

  .Topic {
    font-size: 24px;
  }

  .Description {
    font-size: 4vw;
    margin-top: 10px;
    text-align: center;
  }

  .image-container-heroface {
    display: none;
    top: 10%;
    width: 100%;
    height: 70%;
  }

  .image-container-heroface img {
    width: 150%;
  }

  .EnButton-white {
    padding: 20px 45px;
  }
}

/* For devices with a min-width of 601px and max-width of 768px */
@media (min-width: 601px) and (max-width: 768px) {
  .content1 {
    display: flex;
    align-items: center;
    height: 75vh;
    flex-direction: column;
    justify-content: flex-start;
}
.hero-face {
  position: relative;
  width: 100%;
  height: 50vh;
  min-height: 40vh;
}
.Topic1 {
  text-align: center;
  font-family: Montserrat;
  font-weight: 900;
  font-size: 5vh;
  color: #000000;
}
  .text-container1 {
    width: 80%;
    margin-left: 10%;
  }

  .Topic {
    font-size: 30px;
  }

  .Description {
    text-align: center;
    font-size: 2.8vh;

}
  .image-container-heroface {
    display: none;
    top: 8%;
    width: 80%;
    height: 75%;
  }

  .image-container-heroface img {
    width: 175%;
  }

  .EnButton-white1 {
    background-size: 20vh;
    display:block; 
    margin: 0 auto;
  }
}

/* For devices with a min-width of 769px and max-width: 992px */
@media (min-width: 769px) and (max-width: 992px) {
  .text-container1 {
    width: 70%;
    margin-left: 10%;
  }

  .Topic {
    font-size: 36px;
  }

  .Description {
    font-size: 20px;
    margin-top: 18px;
  }

  .image-container-heroface {
    display: none;
    top: 6%;
    width: 70%;
    height: 80%;
  }

  .image-container-heroface img {
    width: 190%;
  }

  .EnButton-white {
    padding: 30px 75px;
  }
}

/* For devices with a min-width: 993px and max-width: 1200px */
@media (min-width: 993px) and (max-width: 1200px) {
  .text-container1 {
    width: 48vw;
    margin-left: 10%;
  }

  .Topic {
    font-size: 40px;
  }

  .Description {
    font-size: 22px;
    margin-top: 20px;
  }

  .image-container-heroface {
    top: 0%;
    right: -15vh;
    width: 65%;
    height: 85%;
  }

  .image-container-heroface img {
    width: 195%;
  }

  .EnButton-white {
    padding: 35px 85px;
  }
}