/* Civil.css */
@font-face {
  font-family: 'Montserrat-Bold';
  src: url('../../Resources/Fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}


.civil {
  position: relative;
  width: 100%;
  height: 40vw;
}

.content-civil {
  display: flex;
  align-items: center;
  height: 40vw;
}

.text-container-civil {
  width: 35%;
  margin: 0 auto; /* This will center the text container */
  margin-left: 10%; /* Adjust the left margin to shift it slightly towards the left */
}

.title-civil {
  font-family: 'Montserrat-Bold', sans-serif;
  font-weight: bold;
  font-size: 3vw; /* Use viewport width for font size */
  color: white;
}

.description-civil {
  font-family: Montserrat;
  font-size: 1.5vw; /* Use viewport width for font size */
  color: white;
  margin-top: 2vh;
}

.round-button-civil {
  padding: 1vw 2vw; /* Increased padding using viewport width */
  border-radius: 4vw; /* Increased border radius using viewport width */
  background-color: #000;
  color: #43c5e4;
  border: none;
  cursor: pointer;
  font-size: 1.5vw; /* Increased font size using viewport width */
  margin-top: 3vh;
}

.image-container-civil {
  width: 65%;
  position: relative;
  height: 100%;
  overflow: hidden;
}

.stack-imgs-civil img {
  position: absolute;
}

.stack-imgs-civil img:nth-child(1) {
  top: 0;
  left: 0;
  width: 100%; /* Use viewport width for image size */
  height: auto;
  margin-left: 3vw;
}

.stack-imgs-civil img:nth-child(4) {
  top: 10vh; /* Use viewport height for spacing */
  right: 0;
  width: 25vw;
  height: auto;
  margin-right: 0;
}

.scatter-around-civil {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
}

.scatter-around-civil img {
  width: 5vw; /* Use viewport width for image size */
  height: auto;
  margin: 0 0.5vw;
  position: relative;
  animation: popUpAnimation 3s ease alternate infinite;
}

.scatter-around-civil img:nth-child(1) {
  top: 4vh;
  left: -20vw;
}

.scatter-around-civil img:nth-child(2) {
  top: 20vh;
  left: -15vw;
}

.scatter-around-civil img:nth-child(3) {
  top: -3vh;
  left: -14vw;
}

@media screen and (orientation: portrait)  {
  .civil {
    flex-direction: column;
    justify-content: flex-start;
    height: 160vw;

  }

  .content-civil {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 160vw;
  }

  .text-container-civil {
    width: 90%;
    margin-left: 5%;
    margin-top: 5%;
  }

  .title-civil {
    font-size: 7vw; /* Adjust font size for mobile view */
  }

  .description-civil {
    font-size: 3.5vw;
  }

  .round-button-civil {
    font-size: 4vw;
    padding: 2vw 4vw;
  }

  .image-container-civil {
    width: 100%;
    height: 50%; /* Adjust height as needed */
    top: 5%;
  }

  .stack-imgs-civil img:nth-child(1) {
    width: 100%;
    margin-left: 2vm;
  }



  .stack-imgs-civil img:nth-child(4) {
    transform: scale(0.47);
    left: 28vw;
    top: -18vh;
    width: 100%;
  }

  .scatter-around-civil {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .scatter-around-civil img {
    display: none;
    width: 3em;
    margin: 0 2px;
  }


  .scatter-around-civil img:nth-child(1),
  .scatter-around-civil img:nth-child(2),
  .scatter-around-civil img:nth-child(3),
  .scatter-around-civil img:nth-child(4),
  .scatter-around-civil img:nth-child(5),
  .scatter-around-civil img:nth-child(6) {
    top: 10%;
    left: 10%;
  }
}
