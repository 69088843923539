/* SkillDevelop.css */
@keyframes popUpAnimation {
  0% {
    transform: scale(0.8); /* Start with a smaller size */
  }
  50% {
    transform: scale(1.2); /* Increase size halfway through */
  }
  100% {
    transform: scale(1); /* Return to normal size */
  }
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('../../Resources/Fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}


.skill-develop {
  position: relative;
  width: 100%;
  height: 40vw;
}

.content-skill {
  display: flex;
  align-items: center;
  height: 40vw;
}

.text-container-skill {
  width: 35%;
  margin: 0 auto;
  margin-left: 10vw;
}

.title-skill {
  font-family: 'Montserrat-Bold', sans-serif;
  font-weight: bold;
  font-size: 3vw; /* Use viewport width for font size */
  color: white;
}

.description-skill {
  font-family: Montserrat, sans-serif;
  font-size: 1.5vw; /* Use viewport width for font size */
  color: white;
  margin-top: 2vh;
}

.round-button-skill {
  padding: 1vw 2vw; /* Increased padding using viewport width */
  border-radius: 4vw; /* Increased border radius using viewport width */
  background-color: #000;
  color: #43c5e4;
  border: none;
  cursor: pointer;
  font-size: 1.5vw; /* Increased font size using viewport width */
  margin-top: 3vh;
}

.image-container-skill {
  width: 65%;
  position: relative;
  height: 100%;
  overflow: hidden;
}

.stack-imgs-skill img {
  position: absolute;
}

.stack-imgs-skill img:nth-child(1) {
  top: 0;
  left: 0;
  width: 100%; /* Use viewport width for image size */
  height: auto;
  margin-left: 3vw;
}



.stack-imgs-skill img:nth-child(4) {
  top: 5vh; /* Use viewport height for spacing */
  right: 10vw;
  width: 35vw;
  height: auto;
  margin-left: 20vw;
}


@media screen and (orientation: portrait)  {
  .skill-develop {
      flex-direction: column;
      justify-content: flex-start;
      height: 160vw;

  }

  .content-skill {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 160vw;
  }

  .text-container-skill {
      width: 90%;
      margin-left: 5%;
      margin-top: 5%;
  }

  .title-skill {
      font-size: 7vw; /* Adjust font size for mobile view */
  }

  .description-skill {
    font-size: 3.5vw;
  }

  .round-button-skill {
    font-size: 4vw;
    padding: 2vw 4vw;
  }

  .image-container-skill {
      width: 100%;
      height: 50%; /* Adjust height as needed */
      top: 5%;
  }

  .stack-imgs-skill img:nth-child(1) {
      width: 94%;
      margin-left: 1em;
  }



  .stack-imgs-skill img:nth-child(4) {
    width: 70vw;
    top:0vh;
  }


}
