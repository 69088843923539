/* components/Navigation.css */
.navigation {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(67, 197, 228, 0.15);
  background: #FFFFFF;
  position: relative;
  width: 100%;
  z-index: 1000;
  height: 9vh;
  flex-wrap: wrap;
}

.ruddo-logo {
  width: 20%;
  margin: 0 auto;
  margin-left: 10%;
}

.ruddo-logo img {
  max-width: 100%;
  width: 11.5vh;
  margin-top: 1%;
  height: 3.5vh;
  max-height: 40px;
  margin-right: 2%;
}

.nav-links {
  display: flex;
  gap: 15%;
  margin-right: 23%;
}

.NormalCharacterStyle3,
.NormalCharacterStyle17 {
  font-family: Montserrat;
  font-size: 2vh;
  font-weight: 500;
  line-height: 18px;
  text-transform: uppercase;
  color: #101010;
  position: relative;
  text-decoration: none;
  padding-top: 2%;
  padding: 2vh 0vh;
  
}

.NormalCharacterStyle3:hover::after,
.NormalCharacterStyle17:hover::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 100%;
  height: 2px;
  background-color: #ff0000;
  animation: lineAnimation 0.3s forwards;
}

@keyframes lineAnimation {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}

.burger-icon {
  display: none;
}

.burger-icon img {
  width: 30px;
  height: auto;
  cursor: pointer;
}

.dropdown {
  position: static;
  scale: 100%;
}

.dropbtn {
  background-color: transparent;
  color: #000;
  border: none;
  cursor: pointer;
  font-size: 2vh;
  padding: 2vh 0vh;
  text-align: left;
  width: 100%;

}
.subcourse-link-explore-link{
  font-size: 1vh;
  font-weight: bold;
  text-align: left;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  padding-left: 1vw;
  border-radius: 4px;
  overflow: hidden;
}

.dropdown-subcontent {
  display: none;
  background-color: #bebebe;
  border-top: 1px solid #ddd;
  padding: 10px 0;
}

.dropdown-subcontent.show {
  display: block;
}

.dropdown-content a,
.explore-link {
  color: #000;
  padding: 10px 20px;
  text-decoration: none;
  display: block;
  font-size: 1.3vw;
}

.dropdown-content a:hover,
.explore-link:hover {
  background-color: #43c5e4;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.sidebar {
  position: fixed;
  background-color: rgb(185, 226, 241);
  backdrop-filter: blur(10px);
  z-index: 999;
  right: -100%;
  top: 0;
  height: 100vh;
  width: 60vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem;
  transition: right 0.3s ease-in-out;
}

.sidebar.show {
  right: 0;
}

.sidebar .nav-links {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sidebar .nav-links .dropdown-content,
.sidebar .nav-links .dropdown-subcontent {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media (max-width: 800px) {
  .nav-links {
    display: none;
  }
  .burger-icon {
    display: block;
    padding-right: 2vw;
  }
  
  .subcourse-link-explore-link{
    font-size: 2vh;
  }
.dropdown-content,
.dropdown-subcontent {
  display: none;
  position: static;
  background-color: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  width: 100%;
  z-index: 1;
  box-shadow: 0px 4px 4px rgba(65, 148, 169, 0.15);
  border-top: 1px solid #4bbbcf;
  padding: 2vh 1vh;
}
  
}