/*responsive : yes*/
/*responsive test-status: */

.DD {
  position: relative;
  width: 100%;
  height: 100%; /* Adjust the height as needed */
  padding-bottom: 1%;
  overflow-x: hidden;
}

.domain-face {
  position: relative;
  width: 100%;
  height: 80%; /* Adjust the height as needed */
  padding-bottom: 1%;
}

.domain-content {
  border-bottom: 0.2vh solid #ffffff; /* Add border with desired color */
  display: flex;
  align-items: center;
  height: 100%;
}

.domain-text-container {
  width: 32vw; /* Adjust width as needed */
  margin: 0 auto; /* This will center the text container */
  margin-left: 10vw; /* Adjust the left margin to shift it slightly towards the left */
  flex: 1; /* Allow this div to grow and take up remaining space */
  padding: 2vh; /* Add padding for content */
}

.domain-topic {
  font-family: 'Montserrat-Bold', sans-serif;
  font-weight: bold;
  font-size: 3vw;
  color: #000000;
}

.domain-description {
  font-family: Montserrat;
  font-size: 1.3vw;
  color: #000000;
  margin-top: 2vh; /* Adjust margin as needed */
}

.domain-image-container-heroface {
  position: relative;
  border-left: 0.2vh solid #ffffff;
  width: 40vw; /* Adjust the width as needed */
  height: 100%;
  overflow: hidden;
}

.domain-image-container-heroface img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform-origin: right center;
  transition: transform 0.5s ease;
  border-left: 0.1vh solid white; /* Add white border on the right */
}

.domain-enroll-button-white {
  padding: 3.5vh 9vw; /* Adjust the padding to increase the size */
  background-color: transparent;
  border: none;
  cursor: pointer;
  background-image: url('../assets/Asset 10.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transition: background-color 0.3s ease;
}

.domain-enroll-button-white:hover {
  transform: scale(1.1); /* Adjust the scale factor for enlargement */
  transition: transform 0.3s ease; /* Include the transform property in the transition */
}

.domain-download-curriculum {
  color: #000000;
  border: none;
  background: none;
  font-size: 1vw;
  position: relative;
  cursor: pointer;
  width: 12em;
}

.domain-download-curriculum:hover .arrow-down {
  transform: translateY(0.3vh);
}

.arrow-down {
  padding: 0.2vh;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 0.5vh solid transparent;
  border-right: 0.5vh solid transparent;
  border-top: 0.5vh solid #000000;
}

.separator {
  width: 100%;
  height: 0.1vh;
  background-color: #ffffff;
  margin: 2vh 0;
}

.down-learn {
  display: flex;
  position: relative;
  align-items: center;
  border-bottom: 0.2vh solid #ffffff;
}

.learn-container {
  color: #ffffff;
  text-align: center;
  width: 20vw;
  margin: 0 auto; /* This will center the text container */
  margin-left: 10vw;
  position: relative;
  padding-bottom: 2vh;
  margin-right: 0.2em;
}

.learn-container::before {
  content: '';
  position: absolute;
  top: -3.2vh;
  bottom: 0;
  right: -0.2vh;
  height: 9vh;
  width: 0.2vh; /* Adjust line thickness */
  background-color: #ffffff; /* White color for the line */
}

.learn-heading {
  font-weight: bold;
  font-size: 2vh;
  text-transform: uppercase;
}

.logo-container {
  width: 70vw;
  display: flex;
  padding-bottom: 2vh;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
}

.logo-container img {
  width: 6em; /* Adjust the size of the logos */
  height: 3em;
  border-radius: 4vh;
  background-color: transparent;
  padding-right: 10em;
  animation: moveLeft 5s linear infinite; /* Add horizontal loop animation */
}

@keyframes moveLeft {
  0% {
    transform: translateX(0%); /* Start from initial position */
  }
  100% {
    transform: translateX(-100%); /* Move left by 100% of container width */
  }
}

@media screen and (max-width: 600px) {
  .domain-face {
    max-width: 100%; /* Limit the width */
    margin: 0 0 0 0; /* Center horizontally and align left */
    height: 100%;
    position: relative; /* Add positioning context */
    z-index: 1; /* Ensure domain-face is below other elements */
  }

  .domain-content {
    border-bottom: 0;
    flex-direction: column; /* Stack elements vertically */
    align-items: flex-start; /* Align items to the start (left) */
  }

  .domain-image-container-heroface {
    order: 1; /* Move image to the top */
    width: 100%; /* Adjust the width to make the image smaller */
    margin-bottom: 2vh; /* Add space between image and text */
    margin-top: 2vh; /* Add space above the image */
    align-self: flex-start; /* Align the image to the start (left) */
    z-index: 2; /* Ensure the image is on top */
  }

  .domain-image-container-heroface img {
    width: 100%;
    object-fit: contain;
  }

  .domain-text-container {
    order: 2; /* Move text below image */
    width: 80vw; /* Adjust width for smaller screens */
    text-align: left; /* Left-align the text */
    margin-bottom: 2vh; /* Add space below the text */
    z-index: 2; /* Ensure the text is on top */
  }

  .domain-topic {
    font-size: 3vh; /* Reduce font size for smaller screens */
  }

  .domain-description {
    font-size: 1.8vh; /* Reduce font size for smaller screens */
  }
  .domain-download-curriculum {
    color: #000000;
    border: none;
    background: none;
    font-size: 1.7vh;
    position: relative;
    cursor: pointer;
    width: 15em;
}
  .down-learn {
    height: 1vh;
    display: none;
    position: relative;
    align-items: center;
    border-bottom: 0.2vh solid #ffffff;
    padding-bottom: 0.1vh; /* Decrease the height by reducing padding */
    margin-top: 1vh; /* Reduce the space above the section */
  }

  .learn-container {
    color: #ffffff;
    text-align: center;
    width: 30vw;
    margin: 0 0; /* This will center the text container */
    margin-left: 10vw;
    position: relative;
    padding-bottom: 2vh;
  }

  .learn-heading {
    font-size: 0.9vh; /* Reduce the font size */
  }

  /* Adjust buttons */
  .button-container {
    display: flex;
    justify-content: flex-start; /* Align buttons to the left */
    align-items: center;
    gap: 1vh; /* Add space between buttons */
    margin-bottom: 2vh; /* Add space below buttons */
  }

  .domain-enroll-button-white {
    padding: 12vw; /* Reduce the padding to make the button smaller */
    font-size: 3.4vh; /* Adjust the font size */
  }
}

@media screen and (min-width: 601px) and (max-width: 768px) {
  .domain-face {
    max-width: 100%; /* Limit the width */
    margin: 0 0 0 0; /* Center horizontally and align left */
    height: 100%;
    position: relative; /* Add positioning context */
    z-index: 1; /* Ensure domain-face is below other elements */
  }

  .domain-content {
    flex-direction: column; /* Stack elements vertically */
    align-items: flex-start; /* Align items to the start (left) */
  }

  .domain-image-container-heroface {
    order: 1; /* Move image to the top */
    width: 100%; /* Adjust the width to make the image smaller */
    margin-bottom: 2vh; /* Add space between image and text */
    margin-top: 2vh; /* Add space above the image */
    align-self: flex-start; /* Align the image to the start (left) */
    z-index: 2; /* Ensure the image is on top */
  }

  .domain-image-container-heroface img {
    width: 100%;
    object-fit: contain;
  }

  .domain-text-container {
    order: 2; /* Move text below image */
    width: 80vw; /* Adjust width for smaller screens */
    text-align: left; /* Left-align the text */
    margin-bottom: 2vh; /* Add space below the text */
    z-index: 2; /* Ensure the text is on top */
  }

  .domain-topic {
    font-size: 3.5vh; /* Reduce font size for smaller screens */
  }

  .domain-description {
    font-size: 2vh; /* Reduce font size for smaller screens */
  }

  .down-learn {
    height: 1vh;
    display: flex;
    position: relative;
    align-items: center;
    border-bottom: 0.2vh solid #ffffff;
    padding-bottom: 0.1vh; /* Decrease the height by reducing padding */
    margin-top: 1vh; /* Reduce the space above the section */
  }

  .learn-container {
    color: #ffffff;
    text-align: center;
    width: 25vw;
    margin: 0 0; /* This will center the text container */
    margin-left: 10vw;
    position: relative;
    padding-bottom: 2vh;
  }

  .learn-heading {
    font-size: 1.5vh; /* Reduce the font size */
  }

  /* Adjust buttons */
  .button-container {
    display: flex;
    justify-content: flex-start; /* Align buttons to the left */
    align-items: center;
    gap: 1vh; /* Add space between buttons */
    margin-bottom: 2vh; /* Add space below buttons */
  }

  .domain-enroll-button-white {
    padding: 3.5vh 7.5vw; /* Reduce the padding to make the button smaller */
    font-size: 3.4vh; /* Adjust the font size */
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  .domain-text-container {
    width: 35vw; /* Adjust width as needed */
    margin-left: 8vw; /* Adjust the left margin */
  }

  .domain-topic {
    font-size: 3.8vh;
  }

  .domain-description {
    font-size: 2.2vh;
  }

  .learn-container {
    width: 25vw;
  }

  .learn-heading {
    font-size: 1.5vh;
        text-align: left;
  }
}

@media screen and (min-width: 993px) and (max-width: 1200px) {
  .domain-text-container {
    width: 32vw; /* Adjust width as needed */
    margin-left: 10vw; /* Adjust the left margin */
  }

  .domain-topic {
    font-size: 4vh;
  }

  .domain-description {
    font-size: 2.4vh;
  }

  .learn-container {
    width: 20vw;
  }

  .learn-heading {
    font-size: 1.5vh;
    text-align: left;
  }
}
