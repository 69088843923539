@keyframes popUpAnimation {
  0% {
    transform: scale(0.8); /* Start with a smaller size */
  }
  50% {
    transform: scale(1.2); /* Increase size halfway through */
  }
  100% {
    transform: scale(1); /* Return to normal size */
  }
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('../../Resources/Fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}


.scatter-around-cs {
  display: flex;
  justify-content: center;
  align-items: center;
}

.scatter-around-cs img {
  width: 5vw; /* Adjust the size using viewport width */
  height: auto; /* Maintain aspect ratio */
  margin: 1vw; /* Adjust spacing between images using viewport width */
  animation: popUpAnimation 3s ease alternate infinite; /* Adjust duration and timing function */
}

.cs-and-it {
  position: relative;
  width: 100%;
  height: 40vw;
}

.content-cs {
  display: flex;
  align-items: center;
  height: 40vw;
}

.text-container-cs {
  width: 35%;
  margin: 0 auto; /* This will center the text container */
  margin-left: 10vw; /* Adjust the left margin to shift it slightly towards the left */
}

.title-cs {
  font-family: 'Montserrat-Bold', sans-serif;
  font-weight: bold;
  font-size: 3vw;
  color: white;
}

.description-cs {
  font-family: Montserrat;
  font-size: 1.5vw;
  color: white;
  margin-top: 2vh; /* Adjust margin using viewport height */
}

.round-button-cs {
  padding: 1vw 2vw; /* Increased padding using viewport width */
  border-radius: 4vw; /* Increased border radius using viewport width */
  background-color: #000;
  color: #43c5e4;
  border: none;
  cursor: pointer;
  font-size: 1.5vw; /* Increased font size using viewport width */
  margin-top: 3vh;
}

.image-container-cs {
  width: 65%;
  position: relative;
  height: 100%;
  overflow: hidden;
}

.stack-imgs-cs img {
  position: absolute;
}

.stack-imgs-cs img:nth-child(1) {
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  margin-left: 3vw;
}

.stack-imgs-cs img:nth-child(2) {
  top: 40vh; /* Adjust the spacing between images using viewport height */
  left: 4vh;
  width: 25vw;
  height: auto;
  margin-top: 10vh;
}

.stack-imgs-cs img:nth-child(3) {
  top: 40vh; /* Adjust the spacing between images using viewport height */
  left: 38vw;
  width: 25vw;
  height: auto;
  margin-top: 10vh;
}

.stack-imgs-cs img:nth-child(4) {
  top: 25vh; /* Adjust the spacing between images using viewport height */
  left: 20vw;
  width: 20vw;
  height: auto;
}

/* Add similar rules for other images if needed */

.scatter-around-cs {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 20%; /* Adjust the top position as needed */
  left: 60%;
  transform: translateX(-50%);
}

.scatter-around-cs img {
  width: 5vw; /* Adjust the size using viewport width */
  height: auto;
  margin: 0 1vw; /* Adjust the spacing between images using viewport width */
  position: relative;
}

.scatter-around-cs img:nth-child(1) {
  top: 25vh; /* Adjust using viewport height */
  left: -5vw; /* Adjust using viewport width */
}

.scatter-around-cs img:nth-child(2) {
  top: 10vh;
  left: -22vw;
}

.scatter-around-cs img:nth-child(3) {
  top: -8vh;
  left: -14vw;
}

.scatter-around-cs img:nth-child(4) {
  top: -19vh;
  left: -2vw;
}

.scatter-around-cs img:nth-child(5) {
  top: -8vh;
  left: 3vw;
}

.scatter-around-cs img:nth-child(6) {
  top: 45vh;
  left: 2vw;
}

/* Add more rules for additional images if needed */

@media screen and (orientation: portrait)  {
  .content-cs {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 160vw;
  }
  .cs-and-it {
    flex-direction: column;
    justify-content: flex-start;
    height: 160vw;

  }

  .text-container-cs {
    width: 90%;
    margin-left: 5%;
    margin-top: 5%;
  }

  .title-cs {
    font-size: 7vw;
  }

  .description-cs {
    font-size: 3.5vw;
  }

  .round-button-cs {
    font-size: 4vw;
    padding: 2vw 4vw;
  }

  .image-container-cs {
    width: 100%;
    height: 50%; /* Adjust height using viewport height */
    top: 5%;
  }

  .stack-imgs-cs img:nth-child(1) {
    width: 100%;
    margin-left: 2vw;
  }

  .stack-imgs-cs img:nth-child(2) {
    width: 50%;
    top: 10vh;
    left: -10vw;
  }

  .stack-imgs-cs img:nth-child(3) {
    width: 50%;
    top: 10vh;
    left: 50vw;
  }

  .stack-imgs-cs img:nth-child(4) {
    width: 30%;
    top: 10vh;
    left: 30vw;
  }

  .scatter-around-cs {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .scatter-around-cs img {
    display: none; /* Hide images for smaller screens */
  }
}