/* Base styles */
.faq-container {
  background-color: #000;
  color: #fff;
  padding: 20px;
  padding-left: 10em;
  padding-right: 10em;
}

.faq-heading {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.faq-list {
  display: flex;
  flex-direction: column;
}

.faq-item {
  background-color: transparent;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.faq-item:hover {
  background-color: #43c5e4;
  color: #fff;
}

.faq-item.active {
  border-color: #fff;
  background-color: transparent;
  color: #43c5e4;
}

.question {
  font-size: 1.5em;
  font-weight: bold;
}

.answer {
  font-size: 1.2em;
  display: none;
}

.faq-item.active .answer {
  display: block;
  color: #fff;
}

/* Mobile view adjustments */
@media screen and (max-width: 768px) {
  .faq-container {
    padding: 10px;
    padding-left: 2em;
    padding-right: 2em;
  }

  .faq-heading {
    font-size: 1.5em;
  }

  .faq-item {
    padding: 15px;
    font-size: 1em;
  }

  .question {
    font-size: 1.2em;
  }

  .answer {
    font-size: 1em;
  }
}
