/*responsive : yes*/
/*responsive test-status: */
.Values {
    
    padding: 40px;
  background-color: #000; /* Set the background color of the body to black */
    color: #fff;
    
    text-align: center;
  }
  
  .Values h1 {
    font-size: 6vh;
    color: #ffffff;
  font-weight: bold;
  
  text-align: center;
  } 
  
  .advantages {
    display: flex;
    justify-content: space-between;
  }
  
  .advantage1 {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .advantage1 img {
    width: 8vh;
    margin-right: 10px;
  }
  
  .advantage1 p {
    font-size: 2.8vh;
    text-align: left;
    margin-left: 2vh;
  }
  
  .left-advantages{
    width: 32%; /* Adjust width as needed */
      margin: 0 auto; /* This will center the text container */
      margin-left: 10%; /* Adjust the left margin to shift it slightly towards the left */
  
  }
  
  .right-advantages{
    width: 32%; /* Adjust width as needed */
      margin: 0 auto; /* This will center the text container */
      margin-left: 10%; /* Adjust the left margin to shift it slightly towards the left */
  
  }

  @media (max-width: 600px) {
    .Values {
      padding: 15px; /* Further reduce padding for very small screens */
    }
  
    .Values h1 {
      font-size: 32px; /* Adjust font size for very small screens */
    }
  
    .Values h1::after {
      width: 40%; /* Adjust width as needed */
      height: 1px; /* Adjust height as needed */
    }
  
    .advantage1 {
      width: 100%; /* Full width for smaller screens */
      margin: 15px auto; /* Center the advantages */
    }
  
    .advantage1 img {
      width: 8vh;
      margin-right: 10px;
    }
    
    .advantage1 p {
      font-size: 2.3vh;
      text-align: left;
      margin-left: 2vh;
    }
  
    .left-advantages,
    .right-advantages {
      width: 100%; /* Full width for both sections */
      margin: 0; /* Remove margins */
      text-align: left; /* Align text to the left */
    }
  
    .advantages {
      flex-direction: column; /* Stack the advantages sections vertically */
      align-items: center; /* Center align the items */
    }
  }
  
  @media (min-width: 601px) and (max-width: 768px) {
    .Values {
      padding: 20px; /* Maintain padding for medium screens */
    }
  
    .Values h1 {
      font-size: 36px; /* Adjust font size for medium screens */
    }
  
    .Values h1::after {
      width: 50%; /* Maintain width for the line */
      height: 2px; /* Maintain height for the line */
    }
  
    .advantage1 {
      width: 90%; /* Adjust width for medium screens */
      margin: 20px auto; /* Center the advantages */
    }
  
    .advantage1 img {
      width: 8vh;
      margin-right: 10px;
    }
    
    .advantage1 p {
      font-size: 2.3vh;
      text-align: left;
      margin-left: 2vh;
    }
  
    .left-advantages,
    .right-advantages {
      width: 100%; /* Full width for both sections */
      margin: 0; /* Remove margins */
      text-align: left; /* Align text to the left */
    }
  
    .advantages {
      flex-direction: column; /* Stack the advantages sections vertically */
      align-items: center; /* Center align the items */
    }
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    .Values {
      padding: 30px; /* Adjust padding for larger medium screens */
    }
  
    .Values h1 {
      font-size: 40px; /* Adjust font size for larger medium screens */
    }
  
    .Values h1::after {
      width: 50%; /* Maintain width for the line */
      height: 2px; /* Maintain height for the line */
    }
  
    .advantage1 {
      width: 80%; /* Adjust width for larger medium screens */
      margin: 25px auto; /* Center the advantages */
    }
  
    .advantage1 img {
      width: 8vh;
      margin-right: 10px;
    }
    
    .advantage1 p {
      font-size: 2.3vh;
      text-align: left;
      margin-left: 2vh;
    }
  
    .left-advantages,
    .right-advantages {
      width: 48%; /* Adjust width to make them side by side */
      margin: 1%; /* Small margin for spacing */
      text-align: left; /* Align text to the left */
    }
  
    .advantages {
      flex-direction: row; /* Align the advantages sections horizontally */
      align-items: flex-start; /* Align the items to the top */
    }
  }
  
  @media (min-width: 993px) and (max-width: 1200px) {
    .Values {
      padding: 35px; /* Adjust padding for larger screens */
    }
  
    .Values h1 {
      font-size: 42px; /* Adjust font size for larger screens */
    }
  
    .Values h1::after {
      width: 50%; /* Maintain width for the line */
      height: 2px; /* Maintain height for the line */
    }
  
    .advantage1 {
      width: 75%; /* Adjust width for larger screens */
      margin: 30px auto; /* Center the advantages */
    }
  
    .advantage1 img {
      width: 8vh;
      margin-right: 10px;
    }
    
    .advantage1 p {
      font-size: 2.3vh;
      text-align: left;
      margin-left: 2vh;
    }
  
    .left-advantages,
    .right-advantages {
      width: 45%; /* Adjust width to make them side by side */
      margin: 2%; /* Small margin for spacing */
      text-align: left; /* Align text to the left */
    }
  
    .advantages {
      flex-direction: row; /* Align the advantages sections horizontally */
      align-items: flex-start; /* Align the items to the top */
    }
  }
  