/* Management.css */
@font-face {
  font-family: 'Montserrat-Bold';
  src: url('../../Resources/Fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

.management {
  position: relative;
  width: 100%;
  height: 40vw;
}

.content-management {
  display: flex;
  align-items: center;
  height: 40vw;
}

.text-container-management {
  width: 35%;
  margin: 0 auto;
  margin-left: 10vw; /* Adjust the left margin using viewport width */
}

.title-management {
  font-family: 'Montserrat-Bold', sans-serif;
  font-weight: bold;
  font-size: 3vw; /* Use viewport width for font size */
  color: white;
}

.description-management {
  font-family: Montserrat, sans-serif;
  font-size: 1.5vw; /* Use viewport width for font size */
  color: white;
  margin-top: 2vh; /* Use viewport height for spacing */
}

.round-button-management {
  padding: 1vw 2vw; /* Increased padding using viewport width */
  border-radius: 4vw; /* Increased border radius using viewport width */
  background-color: #000;
  color: #43c5e4;
  border: none;
  cursor: pointer;
  font-size: 1.5vw; /* Increased font size using viewport width */
  margin-top: 3vh;
}

.image-container-management {
  width: 65%;
  position: relative;
  height: 100%;
  overflow: hidden;
}

.stack-imgs-management img {
  position: absolute;
}

.stack-imgs-management img:nth-child(1) {
  top: 0;
  left: 0;
  width: 100%; /* Use viewport width for image size */
  height: auto;
  margin-left: 3vw; /* Use viewport width for margin */
}



.stack-imgs-management img:nth-child(4) {
  top: 5vh; /* Use viewport height for spacing */
  left: -10vw;
  width: 35vw;
  height: auto;
  margin-left: 20vw;
}

/* Add styles for the fourth scatter image if needed */
@media screen and (orientation: portrait)  {
  .management {
      flex-direction: column;
      justify-content: flex-start;
      height: 160vw;

  }

  .content-management {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 160vw;
  }

  .text-container-management {
      width: 90%;
      margin-left: 5%;
      margin-top: 5%;
  }

  .title-management {
      font-size: 7vw; /* Adjust font size for mobile view */
  }

  .description-management {
    font-size: 3.5vw;
  }

  .round-button-management {
      font-size: 4vw; /* Adjust font size for mobile view */
      padding: 2vw 4vw;
    }

  .image-container-management {
      width: 100%;
      height: 50%; /* Adjust height as needed */
      top: 5%;
  }

  .stack-imgs-management img:nth-child(1) {
      width: 100%;
      margin-left: 1em;
  }



  .stack-imgs-management img:nth-child(4) {
      transform: scale(2.5);
      margin-top: 10vh;
      left: 10vw;
  }

}
