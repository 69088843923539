.contact-hero-container {
  position: relative;
  width: 100%;
  padding: 20px;
  background-color: #f9f9f9;
}

.contact-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.contact-text-container {
  width: 55vw;
  
  margin: 0 auto;
  margin-left: 10%;
  padding-top: 2.5vw;
  padding-bottom: 1vw;
  box-sizing: border-box;
}

.contact-title {
  font-family: 'Montserrat-Bold', sans-serif;
  font-weight: bold;
  font-size: 3vw;
  color: #000000;
}

.contact-description {
  font-family: Montserrat;
  font-size: 1.3vw;
  color: #666666;
  margin-top: 1vw;
  width: 90%;
}

.contact-options {
  margin-top: 2vw;
}

.contact-subtitle {
  font-family:Montserrat;
  font-size: 1.3vw;
  color: #000000;
  margin-bottom: 1vw;
}

.contact-radio-options {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
}

.radio-option {
  margin: 10px;
}

.radio-option input[type="radio"] {
  display: none;
}

.radio-option label {
  display: inline-block;
  padding: 1.5vw 3vw;
  background-color: #43c5e4;
  color: white;
  font-family: Montserrat;
  font-size: 1vw;
  cursor: pointer;
  border-radius: 0.5vw;
  position: relative;
}

.radio-option input[type="radio"] + label::before {
  content: "";
  display: inline-block;
  width: 0.5vw;
  height: 0.5vw;
  border: 0.2vw solid #ffffff;
  border-radius: 50%;
  margin-right: 1vw;
  vertical-align: middle;
}

.radio-option input[type="radio"]:checked + label::before {
  background-color: #ffffff;
}

.contact-image-container {
  position: relative;
  width: 30vw;
  height: auto;
  overflow: hidden;
  flex-shrink: 0;
}

.contact-image-container img {
  width: 100%;
  height: auto;
  object-fit: cover;
  transform-origin: right center;
  transition: transform 0.5s ease;
}

/* Other styles remain unchanged */

/* Styling for input fields */
.enroll-fields,
.talk-fields,
.apply-fields {
  display: flex;
  flex-wrap: wrap;
  width: 61vw;
  max-width: 175%;
  padding-top: 1vw;
  padding-bottom: 1vw;
  box-sizing: border-box;
}

.enroll-fields input,
.talk-fields input,
.apply-fields input,
.enroll-fields textarea,
.talk-fields textarea,
.apply-fields textarea,
.file-input-container {
  width: calc(42%);
  padding: 1.5vw;
  margin-bottom: 1.5vw;
  margin-right: 1.5vw;
  border: none;
  background-color: #e0e0e0;
  border-radius: 1vw;
  font-family: Montserrat;
  font-size: 1.5vw;
  box-sizing: border-box;
}

.file-input-container {
  position: relative;
  overflow: hidden;
}

.file-input-container input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.file-input-container label {
  display: inline-block;
  padding: 2px 2px;
  color: #6d6d6d;
  border-radius: 5px;
  cursor: pointer;
}

/* Styling for buttons */
.enroll-button,
.send-message-button,
.apply1-button {
  background-color: #43c5e4;
  color: white;
  border: none;
  width: vw;
  height: 4vh;
  border-radius: 5px;
  font-size: 2vh;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.enroll-button:hover,
.send-message-button:hover,
.apply1-button:hover {
  background-color: #3389c5; /* Darken the color slightly on hover */
}

/* Footer styling for form */
.form-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  padding-right: 4%;
  box-sizing: border-box;
}

.support-text {
  font-family: Montserrat;
  font-size: 1.5vw;
  color: #000000; /* Set to black */
}

.support-text strong {
  font-weight: bold;
}
.other-inputs,
.file-input-container{
  width: 53vw;
}
.other-inputs textarea,
.file-input-container textarea {
  width: 100%;
}

@media screen and (orientation: portrait) {
  .contact-text-container {
    width: 90%;
    max-width: 85%;
    margin: 0 auto;
    margin-left: 3%;
    padding-top: 2em;
    padding-bottom: 2em;
    box-sizing: border-box;
  }
  .contact-title {
    font-family: Montserrat;
    font-weight: bold;
    font-size: 7vw;
    color: #000000;
    text-align: left;
  }
  .contact-description{
    font-size: 4vw;
  }
  .contact-subtitle{
    font-size: 4vw;
  }
  
  .contact-image-container {
    width: 0;
  }
  .contact-image-container {
    visibility: hidden;
  }
  .enroll-button {
    background-color: #43c5e4;
    color: white;
    border: none;
    width: 18vw;
    height: 4vh;
    border-radius: 0.5vh;
    font-size: 1.5vh;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
.enroll-fields,
.talk-fields,
.apply-fields {
  flex-direction: column;
  width: 85vw;
}

.enroll-fields input,
.talk-fields input,
.apply-fields input,
.enroll-fields textarea,
.talk-fields textarea,
.apply-fields textarea,
.file-input-container {
  width: 100%;
  height: 5vh;
  max-width: 100%;
  margin-right: 0;
  font-size: 2vw;
}
.contact-radio-options {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
}
.enroll-button,
.send-message-button,
.apply1-button {
width:20vw;
height: fit-content;
font-size: 2.5vw;
}
.support-text{
  font-size: 3vw;
}
.radio-option label{
  font-size: 1.78vw;
}
.other-inputs,
.file-input-container{
  width: 85vw;
}
}